
import Vue from "vue"
import TableFilterSearch
  from "@/components/TableFilterSearch.vue"

export default Vue.extend({
  name: "RangesListTable",

  components: {
    TableFilterSearch
  },

  props: {
    ranges: { type: Array, required: true },
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      selection: [],
      search: "",
      headers: [{
        value: "name",
        text: this.$t("rangesListTableColumnNameLabel")
      }, {
        value: "type",
        text: this.$t("rangesListTableColumnTypeLabel")
      }, {
        value: "area",
        text: this.$t("rangesListTableColumnAreaLabel")
      }, {
        value: "streetAddress",
        text: this.$t("rangesListTableColumnStreetAddressLabel")
      }, {
        value: "country",
        text: this.$t("rangesListTableColumnStreetCountryLabel")
      }, {
        value: "actions",
        sortable: false,
        align: "right"
      }]
    }
  },

  computed: {
    hasSelection(): boolean {
      return this.selection.length > 0
    }
  },

  methods: {
    rangesCreateDialogOpen(): void {
      this.$emit("rangesCreateDialogOpen")
    },

    rangesEditDialogOpen(range): void {
      this.$emit("rangesEditDialogOpen", range)
    },

    rangesRemoveOne(range): void {
      this.$emit("rangesRemoveOne", range)
    },

    rangesRemoveMany(ranges): void {
      this.$emit("rangesRemoveMany", ranges)
    },

    click(range): void {
      this.$router.push(`/ranges/${range.id}`)
    }
  }
})
