
import Vue from "vue"
import { mapActions, mapState } from "vuex"
import RangesListTable from "@/ranges/RangesListTable.vue"
import RangesCreateDialog from "@/ranges/RangesCreateDialog.vue"
import RangesEditDialog from "@/ranges/RangesEditDialog.vue"
import Confirm from "@/components/Confirm.vue"

export default Vue.extend({
  name: "RangesListScreen",

  components: {
    RangesListTable,
    RangesCreateDialog,
    RangesEditDialog,
    Confirm
  },

  data: () => ({
    rangesCreateDialogShown: false,
    rangesEditDialogShown: false,
    rangesEditDialogRange: {}
  }),

  computed: {
    ...mapState("ranges", {
      rangesStateListIsLoading: "listIsLoading",
      rangesStateList: "list",
      rangesStateRemoveOneIsLoading: "removeOneIsLoading",
      rangesStateRemoveManyIsLoading: "removeManyIsLoading"
    }),
    loading(): boolean {
      return (
        this.rangesStateListIsLoading ||
        this.rangesStateRemoveOneIsLoading ||
        this.rangesStateRemoveManyIsLoading
      )
    }
  },

  created() {
    this.rangesActionsList()
  },

  methods: {
    ...mapActions("ranges", {
      rangesActionsList: "list",
      rangesActionsRemoveOne: "removeOne",
      rangesActionsRemoveMany: "removeMany"
    }),

    rangesCreateDialogOpen(): void {
      this.rangesCreateDialogShown = true
    },

    rangesEditDialogOpen(range): void {
      this.rangesEditDialogShown = true
      this.rangesEditDialogRange = range
    },

    async rangesRemoveOne(range): Promise<void> {
      if(!await this.$refs.confirm.dangerous(
        this.$t("rangesRemoveOneConfirmation", {
          rangeName: range.name
        })
      )) return

      try {
        await this.rangesActionsRemoveOne(range)
        this.$success(this.$t("rangesActionsRemoveOneSuccess", {
          rangeName: range.name
        }))
      } catch(e) {
        this.$error(e.message)
      }
    },

    async rangesRemoveMany(): Promise<void> {
      const count = this.rangesSelection.length

      if(!await this.$refs.confirm.dangerous(
        this.$t("rangesRemoveManyConfirmation", {
          rangesCount: count
        })
      )) return

      try {
        await this.rangesActionsRemoveMany(this.rangesSelection)
        this.$success(this.$t("rangesActionsRemoveManySuccess", {
          rangesCount: count
        }))
      } catch(e) {
        this.$error(e.message)
      }
    }
  }
})
