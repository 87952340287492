
/**
 * @typedef {import('../types').Ranges} Ranges
 */

import Vue from "vue"
import { mapActions, mapState } from "vuex"
import DataGrid from "@/components/DataGrid.vue"
import MapView from "@/components/MapView.vue"
import RangesEditDialog from "@/ranges/RangesEditDialog.vue"
import Confirm from "@/components/Confirm.vue"

export default Vue.extend({
  name: "RangesViewScreen",

  components: {
    RangesEditDialog,
    DataGrid,
    MapView,
    Confirm
  },

  data: () => ({
    rangesEditDialogShown: false
  }),

  computed: {
    ...mapState("ranges", {
      rangesStateSelectedIsLoading: "selectedIsLoading",
      rangesStateRemoveOneIsLoading: "removeOneIsLoading",
      rangesStateSelected: "selected"
    }),
    rangesIsLoading(): boolean {
      return (
        this.rangesStateSelectedIsLoading ||
        this.rangesStateRemoveOneIsLoading
      )
    }
  },

  watch: {
    "$route.params.rangeId": {
      immediate: true,
      handler: function(id): void {
        this.rangesActionsSelect({ id })
      }
    }
  },

  methods: {
    ...mapActions("ranges", {
      rangesActionsSelect: "select",
      rangesActionsRemoveOne: "removeOne"
    }),

    rangesEditDialogOpen(): void {
      this.rangesEditDialogShown = true
    },

    async rangesRemoveOne(range): Promise<void> {
      if(!await this.$refs.confirm.dangerous(
        this.$t("rangesRemoveOneConfirmation", {
          rangeName: range.name
        })
      )) return

      try {
        await this.rangesActionsRemoveOne(range)
        this.$success(this.$t("rangesActionsRemoveOneSuccess", {
          rangeName: range.name
        }))
        this.$router.push("/ranges")
      } catch(e) {
        this.$error(e.message)
      }
    }
  }
})
