
import Vue from "vue"
import { mapActions, mapState } from "vuex"
import ActionDialog from "@/components/ActionDialog.vue"
import RangesForm from "@/ranges/RangesForm.vue"
import rangesStub from "./ranges.stub"

export default Vue.extend({
  name: "RangesCreateDialog",

  components: {
    RangesForm,
    ActionDialog
  },

  props: {
    shown: { type: Boolean, default: false }
  },

  data: function() {
    return {
      visible: this.shown,
      form: { ...rangesStub }
    }
  },

  computed: mapState("ranges", {
    rangesStateCreateIsLoading: "createIsLoading"
  }),

  watch: {
    visible(visible): void {
      this.$emit("update:shown", visible)
    },
    shown(visible): void {
      this.visible = visible
    }
  },

  methods: {
    ...mapActions("ranges", {
      rangesActionsCreate: "create"
    }),

    submit(): void {
      this.$refs.rangesForm.submit(async () => {
        try {
          await this.rangesActionsCreate(this.form)
          this.$success(this.$t("rangesActionsCreateSuccess", {
            rangesName: this.form.name
          }))
          this.clear()
          this.close()
        } catch(e) {
          this.$error(e.message)
        }
      })
    },

    clear(): void {
      this.$refs.rangesForm.resetFields()
      this.form = { ...rangesStub }
    },

    close(): void {
      this.visible = false
    }
  }
})
