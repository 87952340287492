
import Vue from "vue"
import { mapState } from "vuex"
import rangesStub from "./ranges.stub"

export default Vue.extend({
  name: "RangesForm",

  props: {
    value: { type: Object, default: () => rangesStub }
  },

  watch: {
    value: {
      deep: true,
      handler(data): void {
        this.$emit("input", data)
      }
    }
  },

  computed: mapState("ranges", {
    rangesStateCountries: "countries",
    rangesStateTypes: "types"
  }),

  methods: {
    submit(cb): void {
      this.$refs.localForm.validate()
        ? cb()
        : this.$error(this.$t("validationError"))
    },

    resetFields(): void {
      this.$refs.localForm.reset()
    }
  }
})
